import '../stylesheets/Header.scss';

function Header ({ avatarName, avatarSrc }) {
  
  return <div className="flex-grow-1 header-container my-2 py-4 d-flex justify-content-center align-items-center">
    <img src={'../../assets/default/images/header-' + avatarSrc + '.png'} alt={avatarName + " - Video Call"} />
  </div>;
}

export default Header;
