import '../stylesheets/Landing.scss';
import eventLogo from '../../assets/default/images/event-logo-kia.png';
import call from '../../assets/default/images/call.png';
import {rpModList} from "../../utils/constants";
import { useEffect, useCallback, useState } from 'react';
import { setLocal } from '../../utils/dataFetching';
import {getEvalByModule} from "../../utils/fetch/llmFetching";
import {formattedDate, formatTimeWithZone} from "../../utils/helpers";
import Overlay from "./Overlay";


const Landing = ({step, setStep, link}) => {

  const cleanStep = step.replace(/-landing$/, "");
  const rpModId = rpModList[cleanStep].id;
  let avatarSrc = '';
  let limit = 5;
  

  if (rpModList[cleanStep] && rpModList[cleanStep].file) {
    avatarSrc = rpModList[cleanStep].file;
  }

  if (rpModList[cleanStep] && rpModList[cleanStep].evalLimit) {
    limit = rpModList[cleanStep].evalLimit;
  }

  const [evaluations, setEvaluations] = useState([]);
  const [evalCount, setEvalCount] = useState(0);
  const [overlayOpen, setOverlayOpen] = useState(false);

  // Avatar Settings
  if (cleanStep && rpModList[cleanStep]) {
    setLocal("avatar_url", rpModList[cleanStep].avatar);
    setLocal("avatar_audio", rpModList[cleanStep].voice);
  }

  const overlayToggle = (open) => {
    setOverlayOpen(open);
  }
  
  const getEvals = useCallback(async () => {
    try {
      if (rpModId) {
        const sessionRes = await getEvalByModule(rpModId);
        if (sessionRes.data?.evaluations) {
          setEvaluations(sessionRes.data.evaluations);
          setEvalCount(sessionRes.data.evaluations.length);
        }
      }
    } catch (e) {
      console.error('Error fetching eval history:', e);
    }


  }, [rpModId]);

  useEffect(() => {
    getEvals();
  }, [getEvals]);

  return (
    <div className="landing-container">
      <img src={eventLogo} alt="Kia 2004" onClick={() => window.location = '/menu'} />
      <div className="customer-container">
        <img src={'../../assets/default/images/' + avatarSrc + '-landing.png'} alt={rpModList[cleanStep].avatarName} />
        <h3>{rpModList[cleanStep].avatarName}</h3>
      </div>
      <div dangerouslySetInnerHTML={{ __html: rpModList[cleanStep].landing }} />
      {evalCount < limit ? (
      <>
        <div className="button-with-label">
          <button type="button" onClick={() => window.location = link}>
            <img src={call} alt="Call" />
          </button>
          <span>Start</span>
        </div>
        </>

      ) : (
        <p></p>
      )}
        <div className="menu-btn">
          <button type="button" onClick={() => overlayToggle(true)}>View Past Performance Evaluations</button>
        </div>


        <Overlay isOpen={overlayOpen} onClose={() => overlayToggle(false)} headerTitle={"Performance Evaluations"} >
          <div className="eval-list">
            {evaluations.length > 0 ? (
                <ul>
                  {evaluations.map((evalInfo, index) => (
                    <li key={index}>
                      <button type="button" onClick={() => window.location = '/' + cleanStep + '-evaluation/'+evalInfo._id}>Past Assessment: {formattedDate(evalInfo.updatedAt, true) + " - " + formatTimeWithZone(evalInfo.updatedAt, true)}</button>
                    </li>
                  ))}

                </ul>
              ) : (
                <p>No evaluations found.</p>
                // keep around to help with styling, making new sessions and evaluating them is time consuming
                // <ul>
                //   <li>
                //     <button type="button">Past Assessment: 6/13/2024 - 10:45 am</button>
                //   </li>
                // </ul>
            )}
          </div>
        </Overlay>
      
    </div>
  );
};

export default Landing;
