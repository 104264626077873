import '../stylesheets/Menu.scss';
import eventLogo from '../../assets/default/images/event-logo-kia.png';

const Menu = ({step, setStep}) => {

  return (
    <div className="menu-container">
      <img src={eventLogo} alt="Kia 2004" />
      <p>Welcome to our new training simulation. Choose a training scenario below to get started.</p>
      <button type="button" onClick={() => window.location = '/role-play-landing'}>Determining Guest Needs</button>
      <button type="button" onClick={() => window.location = '/objection-landing'}>Overcoming Guest Objections 1</button>
      <button type="button" onClick={() => window.location = '/objection-advance-landing'}>Overcoming Guest Objections 2</button>
    </div>
  );
};

export default Menu;
