import AvatarVideo from "../general/AvatarVideo";

import '../stylesheets/RolePlay.scss';
import Header from "../general/Header";
import {rpModList} from "../../utils/constants";
import {sessionCreate} from "../../utils/session/session";
import { setLocal } from '../../utils/dataFetching';
import { useEffect, useCallback, useState } from 'react';

const RolePlay = ({step, setStep}) => {

  const [rpSessionId, setRpSessionId] = useState('');
  const rpModId = rpModList[step].id;
  let avatarName = "";
  let avatarSrc = "";
  let avatarIdle = "";
  
  if (rpModList[step] && rpModList[step].avatarName) {
    avatarName = rpModList[step].avatarName;
  }

  if (rpModList[step] && rpModList[step].file) {
    avatarSrc = rpModList[step].file;
  }

  if (rpModList[step] && rpModList[step].idle) {
    avatarIdle = rpModList[step].idle;
  }

  // Avatar Settings
  if (step && rpModList[step]) {
    setLocal("avatar_url", rpModList[step].avatar);
    setLocal("avatar_audio", rpModList[step].voice);
  }

  const getSession = useCallback(async () => {

    try {
      if (!rpSessionId) {
        const sessionRes = await sessionCreate(rpModId);
        if (sessionRes.data?.session) {
          setRpSessionId(sessionRes.data.session._id);
          setLocal('rp_'+ step + '_session_id', sessionRes.data.session._id);
        }
      }
    } catch (e) {
      console.error('Error fetching session:', e);
    }

  }, [rpModId, step, rpSessionId]); 

  useEffect(() => {
    getSession();
  }, [getSession]);


  return (<div className="role-play-container d-flex flex-column justify-content-start align-items-center">
      <Header avatarName={avatarName} avatarSrc={avatarSrc} />
      {rpSessionId ? (
        <AvatarVideo sessionId={rpSessionId} modId={rpModId} step={step} avatarIdle={avatarIdle} />
      ) : (
        <div></div>
      )}
      
      {/*<SwitchMode step={step} handleSwitchMode={handleSwitchMode} />*/}
    </div>
  );
}

export default RolePlay;
