import React, {useCallback, useEffect, useRef, useState} from 'react';
import './App.scss';

import {Route, Routes} from "react-router-dom";
import {getLocal, setLocal} from "./utils/dataFetching";

import Menu from "./components/general/Menu";
import Landing from "./components/general/Landing";
import Evaluation from './components/general/Evaluation';
import PromptTest from './components/general/PromptTest';
import {useAuth} from "./context/AuthContext";
import RolePlay from "./components/featureset/RolePlay";
import Waveform from './components/featureset/Waveform';
import Tester from "./components/general/Tester";
import VoiceTest from './components/general/VoiceTest';

import {needsLink, objectionLink, advanceLink} from "./utils/constants";


const queryParams = new URLSearchParams(window.location.search);
const ccode = queryParams.get('key') || getLocal('ccode');
const brand = queryParams.get('brand')

const featureUrls = [
  'role-play-landing',
  'menu',
  'prompt-test',
  'voice-test',
  'role-play',
  'waveform',
  'objection-landing',
  'objection',
  'objection-advance-landing',
  'objection-advance',
  'role-play-evaluation',
  'objection-advance-evaluation',
  'objection-evaluation',
  'new'
];

const vehicleUrls = [
  'tesla-cybertruck',
  'ford-f-150-lightning',
  'ford-mustang-mach-e',
  'gmc-hummer-ev-suv-3x',
  'hyundai-ioniq-6',
  'kia-ev6',
  'lucid-air',
  'mercedes-benz-eqs-450-plus',
  'polestar-polestar2',
  'rivian-r1s'
];

window.onpopstate = () => {
  window.location.reload();
};

function App() {
  document.title = 'Apex AI';

  const { isAuthenticated } = useAuth();
  //const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { login } = useAuth();
  const navigate = useCallback ((p) => {
    // This currently fixes the issues encountered by switching between modes
    //    the speech recognition service doesn't get unloaded (commented out bc it was causing other issues)
    window.location = p;
  },[]);
  const attemptLogin = useCallback(async (ccode) => {
    //console.log(submitted);
    if (!submitted) {
      setSubmitted(true);
      console.log("");
      console.log('attempting Login', ccode);

      try {
        let loginResult = await login(ccode);
        console.log(loginResult);

        if (loginResult === 'wait') {
          //window.location.reload();
          return;
        }
        if (loginResult.status === 200) {
          //handleLoginSuccess(ccode);
        } else if (loginResult.status === 'error') {
          console.log('Invalid key');
        } else {
          console.log(loginResult && !loginResult.success ? loginResult.message : "An unexpected error occurred.");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        //setTimeout(() => setSubmitted(false),2000);
      }
    }
  }, [login, submitted]);

  useEffect(() => {

    const attempt = async () => {
      console.log('effect',ccode,isAuthenticated, submitted);

      if (ccode && !isAuthenticated && !submitted) {
        console.log(isAuthenticated, submitted);
        await attemptLogin(ccode);
      }
    }

    attempt();
  }, [ attemptLogin, submitted, isAuthenticated]);

  const audioRef = useRef(null);
  const [audioPlayed, setAudioPlayed] = useState(false);

  const pth = useCallback (() => {
    const p = window.location.pathname.split('/');
    return p.length > 0 ? p[1] : '';
  },[]);

  const [step, setStep] = useState(pth());

  const handleLogout = useCallback((e) => {
    e?.preventDefault();
    // localStorage.clear();
    // sessionStorage.clear();
    setLocal('auth_token','');
    setLocal('user','');
    setLocal('session_id','');
    navigate('/');
    setStep('');
  },[navigate]);

  const handleAppOnClick = useCallback(() => {
    if (!audioPlayed) {
      setAudioPlayed(true);
      if (!audioRef.current.paused && !audioRef.current.currentTime) {
        audioRef.current.src = '/speech.mp3';
        audioRef.current.play();
      }
    }
  },[audioPlayed]);

  useEffect(() => {
    //console.log('use EFFECT');
    async function fetchToken() {
      try {
        const token = getLocal("auth_token");
        if (!token) {
          // const API_TOKEN = await getToken();
          // localStorage.setItem("auth_token", API_TOKEN);
        }
      } catch (error) {}
    }

    fetchToken();

    return () => {
      //console.log('removing auth token');
      //localStorage.removeItem("auth_token");
    };
  }, []);

  useEffect(() => {
    // console.log('use EFFECT1');
    const currentSeg = pth();

    if (step && step !== currentSeg) {
      // console.log('use EFFECT2');
      navigate('/'+step);
      return;
    }

    if (isAuthenticated) {
      if (!featureUrls.includes(currentSeg) && !vehicleUrls.includes(currentSeg)) {
        // console.log('use EFFECT3');
        
        // Redirect to brand if exist
        if (brand) {
          setStep(brand);
          navigate('/' + brand);
        } else {
          setStep('menu');
          navigate('/menu');
        }
        
        return;
      }
    }
    else {
      if (currentSeg !== '' && currentSeg !== 'new') {
        console.log('use EFFECT4');
        setStep('');
        navigate('/');
        return;
      }
    }

  }, [isAuthenticated, navigate, pth, step, handleLogout ]);

  return (
    <div className={`col-12 App ` + (vehicleUrls.includes(step) ? 'waveform ' : '') + `${step} ${audioPlayed ? 'audio-enabled' : ''}`} onClick={handleAppOnClick}>
      {/*<Header handleLogout={handleLogout} isAuthenticated={isAuthenticated} />*/}
      {isAuthenticated ? (
        <Routes>
          <Route path="/menu" element={<Menu step={step} setStep={setStep} />} />
          <Route path="/prompt-test" element={<PromptTest />} />
          <Route path="/voice-test" element={<VoiceTest />} />

          <Route path="/role-play-landing" element={<Landing step={step} setStep={setStep} link={needsLink} />} />
          <Route path="/role-play/" element={<RolePlay step={step} setStep={setStep} />} />
          <Route path="/role-play-evaluation/*" element={<Evaluation step={step} setStep={setStep} />} />
          
          <Route path="/objection-landing" element={<Landing step={step} setStep={setStep} link={objectionLink} />} />
          <Route path="/objection/" element={<RolePlay step={step} setStep={setStep} />} />
          <Route path="/objection-evaluation/*" element={<Evaluation step={step} setStep={setStep} />} />
          
          <Route path="/objection-advance-landing/" element={<Landing step={step} setStep={setStep} link={advanceLink} />} />
          <Route path="/objection-advance/" element={<RolePlay step={step} setStep={setStep} />} />
          <Route path="/objection-advance-evaluation/*" element={<Evaluation step={step} setStep={setStep} />} />

          <Route path="/new" element={<Tester />} />
          {vehicleUrls.map(url => {
            return (
              <Route key={url} path={'/' + url} element={<Waveform step={step} setStep={setStep} audioRef={audioRef} audioPlayed={audioPlayed} brand={url} />} />
            );
          })}
        </Routes>
      ) : (
        <Routes>
          {/*<Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />} />*/}
          <Route path="/" element={<Menu />} />x
          <Route path="/new" element={<Tester />} />
        </Routes>
      )}
      <audio ref={audioRef} autoPlay src='/speech.mp3' crossOrigin="anonymous" />
    </div>
  );
}

export default App;
