import axios from "axios";
import {getLocal} from "../dataFetching";
import { replaceWords } from "../helpers";
import { replaceList } from "../constants";

const getAudioTranscript = async (audioData, mimeType, fileType)=> {
  try {
    const responsePromise = new Promise((resolve, reject) => {
      console.log("inside start of responsePromise");
      const reader = new FileReader();
      reader.readAsDataURL(audioData);
      reader.onloadend = async () => {
        try {
          console.log("in reader.onloadend");
          const base64data = reader.result;
          let params = { audioData: base64data, mimeType, provider: "faster", fileType };
          console.log(params);
          
          const res = await axios.post(`${process.env.REACT_APP_API}/api/transcribe-audio/`, params);
          resolve(res.data);
        } catch (e) {
          console.log("Error POSTING");
          console.log(e);
          reject(e);
        }
      }
    });

    const apiResponse = await Promise.all([responsePromise]);
    console.log("apiResponse:");
    console.log(apiResponse);
    return apiResponse[0];
  } catch (e) {
    throw new Error(e);
  }
}

const getAudioResponse = async (text, sessionId, audio = null)=> {
  try {
    
    let formatted = replaceWords(text, replaceList);

    if (!sessionId) {
      throw new Error('Session ID not found');
    }
    const voiceOption = audio || getLocal("avatar_audio");
    const data = {
      text: formatted,
      voiceOption
    }
    const response = await axios.post(`${process.env.REACT_APP_API}/api/tts/`,data);

    if (response.data?.filepath) {
      response.data.filepath = process.env.REACT_APP_API + response.data.filepath;
    }

    console.log(response);
    return response;
  } catch (e) {
    console.log(e);
    throw new Error('An error occurred while getting LLM Response');
  }
}

export { getAudioTranscript, getAudioResponse }